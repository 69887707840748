.amplify-tabs {
  display: none;
}

[data-amplify-router-content] > div {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 0;
}
